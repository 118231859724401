import type { Role as T, ErrorT} from '~/types'

export const useRoleStore = defineStore('roleStore', () => {
   const config = useRuntimeConfig()
   const headers = computed(() => { return useAuthStore().headers })
   const roles = ref<T[]>([])
   const isPending = ref(false)

   function $reset(): void {
      roles.value = []
      isPending.value = false
   }

   const apiUrl = computed(() => `${config.public.API_URL}/api/role/` )

   const { data: fetchData, error: fetchError, execute: execFetch } = useFetch(() => { return apiUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })
   async function fetchRoles(): Promise<void> {
      console.log('roleStore.fetchRoles()')
      if (isPending.value) return

      isPending.value = true

      await execFetch()

      if (fetchError.value) {
         console.log('roleStore.fetchRoles().fetchError:', fetchError.value as ErrorT)
         showError(fetchError.value as ErrorT)
      }
      else if (fetchData.value) {
         console.log('roleStore.fetchRoles().fetchData:', fetchData.value)
         roles.value = fetchData.value.data as Array<T>
      }

      isPending.value = false
   }

   return {
      roles, fetchRoles, $reset
   }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useRoleStore, import.meta.hot))
}